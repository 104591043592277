import React, { useEffect, useState } from "react";
import AgoraRTC from "agora-rtc-sdk-ng";
import { createNotification } from "react-redux-notify";
import { useDispatch, useSelector } from "react-redux";
import { getErrorNotificationMessage } from "../helper/NotificationMessage";
import { EditorBlock } from "draft-js";
import { updateSingleLiveVideoStart } from "../../store/actions/LiveVideoAction";
import { useHistory } from "react-router-dom/cjs/react-router-dom";
import { videoList, localUser } from "../../store/actions/VideoCallAction";

const useAgoraRTC = (client) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const localUserData = useSelector((state) => state.liveVideo.localUser);
  const [localVideoTrack, setLocalVideoTrack] = useState(undefined);
  const [localVideo, setLocalVideo] = useState(undefined);
  const [localAudioTrack, setLocalAudioTrack] = useState(undefined);
  const [joinState, setJoinState] = useState(false);
  const [isOwner, setIsOwner] = useState(false);
  const [remoteUsers, setRemoteUsers] = useState([]);
  const [isStreamEnded, setIsStreamEnded] = useState(false);
  const [mediaStatus, setMediaStatus] = useState({
    audio: {
      muted: false,
    },
    video: {
      muted: false,
      fullScreen: false,
    },
    permissionDenied: null,
  });

  const createLocalTracks = async (audioConfig, videoConfig) => {
    const [microphoneTrack, cameraTrack] =
      await AgoraRTC.createMicrophoneAndCameraTracks(audioConfig, videoConfig);
    setLocalAudioTrack(microphoneTrack);
    setLocalVideoTrack(cameraTrack);
    dispatch(
      localUser({
        videoTrack: cameraTrack,
        audioTrack: microphoneTrack,
        mediaStatus: {
          video: { muted: false },
          audio: { muted: false },
        },
      })
    );
    return [microphoneTrack, cameraTrack];
  };

  const createAudioTrack = async (audioConfig) => {
    const microphoneTrack = await AgoraRTC.createMicrophoneAudioTrack(
      audioConfig
    );
    setLocalAudioTrack(microphoneTrack);
    return microphoneTrack;
  };

  const unPublishLocalTracks = async () => {
    const [microphoneTrack, cameraTrack] = await createLocalTracks();
    await client.unpublish([microphoneTrack, cameraTrack]);
  };

  const join = async (appid, channel, token, role, mode) => {
    if (!client || !role) return;
    if (role == "host") {
      localStorage.setItem("isOwner", true);
    }
    if (mode === "live") {
      client.setClientRole("host");

      await client.join(appid, channel, token || null).catch((e) => {
        leaveRtcChannel();
      });

      await navigator.mediaDevices
        .getUserMedia({ audio: true, video: true })
        .then(async (res) => {
          setLocalVideo(res);
          const [microphoneTrack, cameraTrack] = await createLocalTracks();
          cameraTrack.play("agora_local", { fit: "cover", mirror: true });
          if (role == "host") {
            await client.publish([microphoneTrack, cameraTrack]);
          } else {
            await client.publish([cameraTrack]);
          }

          window.client = client;
          window.videoTrack = cameraTrack;
        })
        .catch((e) => {
          setMediaStatus((prev) => {
            return {
              ...prev,
              permissionDenied: true,
            };
          });
          console.log(e.message);
          const notificationMessage = getErrorNotificationMessage(e.message);
          dispatch(createNotification(notificationMessage));
        });
      dispatch(localUser({ joinState: true }));
      setJoinState(true);
      setMediaStatus((prev) => {
        return {
          ...prev,
          permissionDenied: false,
        };
      });
    } else {
      await navigator.mediaDevices
        .getUserMedia({ audio: true, video: true })
        .then(async (res) => {
          await client.join(appid, channel, token || null).catch((e) => {
            leaveRtcChannel();
          });
          const [microphoneTrack, cameraTrack] = await createLocalTracks();
          await client.publish([microphoneTrack, cameraTrack]);
          window.client = client;
          window.videoTrack = cameraTrack;
          dispatch(localUser({ joinState: true }));
          setJoinState(true);
          setMediaStatus((prev) => {
            return {
              ...prev,
              permissionDenied: false,
            };
          });
        })
        .catch((e) => {
          setMediaStatus((prev) => {
            return {
              ...prev,
              permissionDenied: true,
            };
          });
          const notificationMessage = getErrorNotificationMessage(e.message);
          dispatch(createNotification(notificationMessage));
        });
    }
  };

  const unpublishTracks = async (track) => {
    if (track) {
      await client.unpublish(track);
    } else {
      await client.unpublish();
    }
  };

  const leaveRtcChannel = async (isHost = true) => {
    if (localAudioTrack) {
      localAudioTrack.stop();
      localAudioTrack.close();
    }
    if (localVideoTrack) {
      localVideoTrack.stop();
      localVideoTrack.close();
    }
    if (isHost) {
      // Traverse all remote users.
      setRemoteUsers([]);
      dispatch(localUser());
      setJoinState(false);
      await client.leave();
      setIsStreamEnded(true);
      localStorage.removeItem("isOwner");
      localStorage.removeItem("live_video_unique_id");
    } else {
      await client.leave();
      localStorage.removeItem("isOwner");
      localStorage.removeItem("live_video_unique_id");
      window.location.assign("/live-videos-history");
    }
  };
  const muteAudio = async () => {
    if (localUserData.data.audioTrack) {
      await localUserData.data.audioTrack.setMuted(
        !localUserData.data.audioTrack.muted
      );
      dispatch(
        localUser({
          mediaStatus: {
            audio: { muted: localUserData.data.audioTrack.muted },
            video: { muted: localUserData.data.videoTrack.muted },
          },
        })
      );
    }
  };

  const muteVideo = async () => {
    if (localUserData.data.videoTrack) {
      await localUserData.data.videoTrack.setMuted(
        !localUserData.data.videoTrack.muted
      );
      dispatch(
        localUser({
          mediaStatus: {
            video: { muted: localUserData.data.videoTrack.muted },
            audio: { muted: localUserData.data.audioTrack.muted },
          },
        })
      );
    }
  };

  const toggleFullScreen = (maximize) => {
    if (maximize) {
      history.push(
        `/join-live/${localStorage.getItem("live_video_unique_id")}`
      );
    } else {
      setMediaStatus((prevMediaStatus) => {
        if (prevMediaStatus.video.fullScreen) {
          document.body.style.overflow = "auto";
        } else {
          document.body.style.overflow = "hidden";
        }
        return {
          ...prevMediaStatus,
          video: {
            ...prevMediaStatus.video,
            fullScreen: !prevMediaStatus.video.fullScreen,
          },
        };
      });
    }
  };

  useEffect(() => {
    if (!client) return;
    setRemoteUsers(client.remoteUsers);
    const handleUserPublished = async (user, mediaType) => {
      await client.subscribe(user, mediaType);
      setRemoteUsers((remoteUsers) => Array.from(client.remoteUsers));
      dispatch(videoList(client.remoteUsers));
    };

    const handleUserUnpublished = (user) => {
      setRemoteUsers((remoteUsers) => Array.from(client.remoteUsers));
      dispatch(videoList(client.remoteUsers));
    };

    const handleUserJoined = async (user) => {
      setRemoteUsers((remoteUsers) => Array.from(client.remoteUsers));
      dispatch(videoList(client.remoteUsers));
    };

    const handleUserLeft = async (user) => {
      if (!localStorage.getItem("isOwner")) {
        localStorage.removeItem("isOwner");
        localStorage.removeItem("live_video_unique_id");
        setRemoteUsers([]);
        dispatch(localUser());
        dispatch(videoList([]));
        await client.leave();
        window.location.assign("/live-videos-history");
      }
      setRemoteUsers((remoteUsers) => Array.from(client.remoteUsers));
      dispatch(videoList(client.remoteUsers));
    };

    client.on("user-published", handleUserPublished);
    client.on("user-unpublished", handleUserUnpublished);
    client.on("user-joined", handleUserJoined);
    client.on("user-left", handleUserLeft);

    return () => {
      client.off("user-published", handleUserPublished);
      client.off("user-unpublished", handleUserUnpublished);
      client.off("user-joined", handleUserJoined);
      client.off("user-left", handleUserLeft);
    };
  }, [client]);

  return {
    localAudioTrack,
    localVideoTrack,
    joinState,
    leaveRtcChannel,
    join,
    remoteUsers,
    isStreamEnded,
    muteAudio,
    muteVideo,
    mediaStatus,
    toggleFullScreen,
    unpublishTracks,
    unPublishLocalTracks,
    localVideo,
  };
};

export default useAgoraRTC;
