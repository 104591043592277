import React, { useEffect, useState } from "react";
import {
  Form,
  Button,
  Image,
  Modal,
  Tab,
  Nav,
  Row,
  Col,
} from "react-bootstrap";
import { createNotification } from "react-redux-notify/lib/modules/Notifications";
import { connect } from "react-redux";
import { translate, t } from "react-multi-lang";
import configuration from "react-global-configuration";
import PaypalExpressBtn from "react-paypal-express-checkout";
import { getErrorNotificationMessage } from "../helper/NotificationMessage";
import { prUserPaymentByWalletStart } from "../../store/actions/PersonalizeAction";
import { fetchWalletDetailsStart } from "../../store/actions/WalletAction";
import { Link, useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import Skeleton from "react-loading-skeleton";
const $ = window.$;

const PaymentModal = (props) => {
  const history = useHistory();
  const dispatch = useDispatch();
  const wallet = useSelector((state) => state.wallet.walletData);
  const userPaymentByWallet = useSelector(
    (state) => state.personalize.userPaymentByWallet
  );
  const [paymentType, setPaymentType] = useState(
    localStorage.getItem("default_payment_method")
  );
  const [skipRender, setSkipRender] = useState(true);
  const [reloadWallet, setReloadWallet] = useState(true);

  const handleSubmit = () => {
    const { type, unique_id, amount, amount_formatted, ...data } = props.show;
    dispatch(
      prUserPaymentByWalletStart({
        ...data,
        personalized_request_unique_id: props.show.unique_id,
      })
    );
  };

  useEffect(() => {
    if (Object.keys(userPaymentByWallet.data).length > 0 && !skipRender) {
      if (props.show.product_type == 1) props.onHide();
      else {
        history.push("/personal-request-table");
      }
    }
    setSkipRender(false);
  }, [userPaymentByWallet]);

  useEffect(() => {
    dispatch(fetchWalletDetailsStart());
  }, [reloadWallet]);

  return (
    <>
      {/* <Modal
        {...props}
        centered
        size="lg"
        className="modal-dialog-center sent-tip-modal"
      >
        <>
          <Modal.Header closeButton>
            <Modal.Title>{t("personalized_req_payment")}</Modal.Title>
          </Modal.Header>
          <Modal.Body className="subscription-tip-ppv-tab">
            <Tab.Container
              id="left-tabs-example"
              defaultActiveKey={paymentType}
            >
              <Row>
                <Col sm={3}>
                  <Nav variant="pills" className="flex-column">
                    {configuration.get(
                      "configData.is_wallet_payment_enabled"
                    ) == 1 ? (
                      <Nav.Item>
                        <Nav.Link
                          onClick={() => setPaymentType("WALLET")}
                          eventKey="WALLET"
                        >
                          {t("wallet")}
                        </Nav.Link>
                      </Nav.Item>
                    ) : null}
                  </Nav>
                </Col>
                <Col sm={9}>
                  <div className="card-stripe-box">
                    <Form>
                      <Form.Group
                        className="mb-3"
                        controlId="exampleForm.ControlInput1"
                      >
                        <Form.Control
                          type="text"
                          placeholder={t("pay_amount")}
                          value={props?.show.amount}
                          disabled
                        />
                      </Form.Group>
                      <Tab.Content>
                        {configuration.get(
                          "configData.is_wallet_payment_enabled"
                        ) == 1 ? (
                          <Tab.Pane eventKey="WALLET">
                            {wallet.loading ? (
                              ""
                            ) : (
                              <div className="card-stripe-box">
                                <div className="wallet-balence-amount">
                                  <h4>{t("available")}</h4>
                                  <p>
                                    {
                                      wallet.data.user_wallet
                                        .remaining_formatted
                                    }
                                  </p>
                                </div>
                                {props?.show.amount >
                                wallet.data.user_wallet.remaining ? (
                                  <div className="">
                                    <p className="conv-desc desc">
                                      {t(
                                        "low_wallet_balance_tips_payment_para"
                                      )}
                                    </p>
                                    <div className="d-flex">
                                      <Link
                                        to="/wallet"
                                        className="withdraw-money-btn"
                                      >
                                        {t("add_wallet_amount")}
                                      </Link>
                                    </div>
                                  </div>
                                ) : null}
                              </div>
                            )}
                          </Tab.Pane>
                        ) : null}
                      </Tab.Content>
                    </Form>
                  </div>
                </Col>
              </Row>
            </Tab.Container>
          </Modal.Body>
          <Modal.Footer>
            <Button
              type="button"
              className="btn btn-success"
              data-dismiss="modal"
              onClick={handleSubmit}
              disabled={wallet.loading || userPaymentByWallet.loading}
            >
              {userPaymentByWallet.loading ? t("loading") : t("pay")}
            </Button>
            <Button
              type="button"
              className="btn btn-danger"
              data-dismiss="modal"
              disabled={userPaymentByWallet.loading}
              onClick={props.onHide}
            >
              {t("cancel")}
            </Button>
          </Modal.Footer>
        </>
      </Modal> */}
      <Modal
        {...props}
        size="xl"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        className="pay-amount-modal"
      >
        <Modal.Header closeButton>
          <Modal.Title>{t("personalized_req_payment")}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="pay-amount-modal-body">
            <div class="pay-wallet-sec ">
              <div className="pay-amount-head ">
                <h4>{t("payment_method")}</h4>
              </div>
              {wallet.loading ? (
                <Skeleton height={45} borderRadius={10} />
              ) : Object.keys(wallet.data).length > 0 ? (
                <div className="services-card-wrapped">
                  <div className="go-live-select-lable">
                    <div class="form-check">
                      <label class="form-check-label" for="1">
                        <div className="service-card-custome">
                          <div className="check-tick">
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="25"
                              height="25"
                              enableBackground="new 0 0 512 512"
                              viewBox="0 0 24 24"
                            >
                              <path
                                fill="#9f4298"
                                d="M12 0C5.383 0 0 5.383 0 12s5.383 12 12 12 12-5.383 12-12S18.617 0 12 0zm-.091 15.419a2.001 2.001 0 01-2.823-.005l-2.782-2.696 1.393-1.437 2.793 2.707 5.809-5.701 1.404 1.425-5.793 5.707z"
                                data-original="#000000"
                              ></path>
                            </svg>
                          </div>
                          <div className="service-card-custome-sec">
                            <span className="service-card-custome-info">
                              Wallet:
                            </span>
                            <span className="wallet-amount">
                              <Image
                                src="assets/images/phase4/wallet.svg"
                                className="wallet-icon"
                              />
                              {wallet.data?.user_wallet?.remaining_formatted}
                            </span>
                          </div>
                        </div>
                      </label>
                    </div>
                  </div>
                  {wallet.data.user_wallet.remaining < props.show.amount && (
                    <p className="text-danger">
                      {t("low_wallet_balance_tips_payment_para")}
                    </p>
                  )}
                  {wallet.data.user_wallet.remaining < props.show.amount && (
                    <div className="add-wallet-btn-sec">
                      <Button
                        className="default-btn"
                        onClick={() => history.push("/wallet")}
                      >
                        {t("add_wallet_amount")}
                      </Button>
                    </div>
                  )}
                </div>
              ) : (
                <div className="d-flex justify-content-center">
                  <div>
                    <h5>{t("something_went_wrong")}</h5>
                    <Button
                      className="button-below-text"
                      onClick={() => setReloadWallet(!reloadWallet)}
                    >
                      {t("reload_wallet")}
                    </Button>
                  </div>
                </div>
              )}
            </div>
            <div class="pay-amount-sec">
              <div className="pay-amount-head">
                <h4>{t("payment_details")}</h4>
                {/* <p>Lorem ipsum dolor sit amet.</p> */}
              </div>
              <div className="coupon-input">
                {/* <Form.Group controlId="formBasicEmail">
                    <InputGroup>
                      <Form.Control
                        type="text"
                        aria-label="text"
                        value="5BDFKHT6K98Q"
                      />
                      <InputGroup.Text>
                        <Link to="#">Apply</Link>
                      </InputGroup.Text>
                    </InputGroup>
                  </Form.Group> */}
                <div className="coupon-container">
                  <div className="coupon-detail">
                    <Image
                      className="coupon-image"
                      src={window.location.origin + "/assets/images/Coupon.svg"}
                    />

                    <div className="coupon-text">
                      <p className="text-dark coupon-code">5BDFKHT6K98Q</p>
                      <p className="text-success coupon-saved">
                        {t("saved")} 20.00 tokens
                      </p>
                    </div>
                  </div>

                  <Link className="coupon-remove" to="#">
                    {t("remove")}
                  </Link>
                </div>
              </div>
              <div className="pay-modal-token-sec">
                <div className="pay-modal-token">
                  <p>{t("tokens")}</p>
                  <p>{props?.show.amount_formatted}</p>
                </div>
                <div className="pay-modal-token">
                  <h5>{t("total_token")}</h5>
                  <h4>{props?.show.amount}</h4>
                </div>
              </div>
              <Button
                className="default-btn"
                onClick={() => handleSubmit()}
                disabled={
                  wallet.loading ||
                  userPaymentByWallet.loading ||
                  wallet.data.user_wallet.remaining < props.show.amount
                }
              >
                {userPaymentByWallet.loading ? t("loading") : t("pay")}
              </Button>
            </div>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default translate(PaymentModal);
