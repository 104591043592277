import {
  SEND_TIP_BY_STRIPE_START,
  SEND_TIP_BY_STRIPE_SUCCESS,
  SEND_TIP_BY_STRIPE_FAILURE,
  SEND_TIP_BY_WALLET_START,
  SEND_TIP_BY_WALLET_SUCCESS,
  SEND_TIP_BY_WALLET_FAILURE,
  SEND_TIP_BY_PAYPAL_START,
  SEND_TIP_BY_PAYPAL_SUCCESS,
  SEND_TIP_BY_PAYPAL_FAILURE,
  SEND_TIP_BY_CCBILL_START,
  SEND_TIP_BY_CCBILL_SUCCESS,
  SEND_TIP_BY_CCBILL_FAILURE,
  SEND_TIP_BY_COINPAYMENT_START,
  SEND_TIP_BY_COINPAYMENT_SUCCESS,
  SEND_TIP_BY_COINPAYMENT_FAILURE,
  CUSTOM_TIP_PAYMENT_START,
  CUSTOM_TIP_PAYMENT_SUCCESS,
  CUSTOM_TIP_PAYMENT_FAILURE,
  FETCH_SUBSCRIPTION_LIST_START,
  FETCH_MORE_SUBSCRIPTION_LIST_START,
  FETCH_SUBSCRIPTION_LIST_SUCCESS,
  FETCH_SUBSCRIPTION_LIST_FAILURE
} from "../actions/ActionConstant";

const initialState = {
  tipStripe: {
    data: {},
    loading: true,
    error: false,
    inputData: {},
    loadingButtonContent: null,
    buttonDisable: false,
  },
  tipWallet: {
    data: {},
    loading: true,
    error: false,
    inputData: {},
    loadingButtonContent: null,
    buttonDisable: false,
  },
  tipPaypal: {
    data: {},
    loading: true,
    error: false,
    inputData: {},
    loadingButtonContent: null,
    buttonDisable: false,
  },
  tipCCBill: {
    data: {},
    loading: true,
    error: false,
    inputData: {},
    loadingButtonContent: null,
    buttonDisable: false,
  },
  tipCoinPayment: {
    data: {},
    loading: true,
    error: false,
    inputData: {},
    loadingButtonContent: null,
    buttonDisable: false,
  },
  customTipPayment: {
    data: {},
    loading: true,
    error: false,
    loadingButtonContent: null,
    buttonDisable: false,
  },
  subscriptionList: {
    data: {},
    loading: true,
    error: false,
  },
};

const SendTipReducer = (state = initialState, action) => {
  switch (action.type) {
    case SEND_TIP_BY_STRIPE_START:
      return {
        ...state,
        tipStripe: {
          data: {},
          loading: true,
          error: false,
          inputData: action.data,
          loadingButtonContent: "Loading... Please wait.",
          buttonDisable: true,
        },
      };
    case SEND_TIP_BY_STRIPE_SUCCESS:
      return {
        ...state,
        tipStripe: {
          data: action.data,
          loading: false,
          error: false,
          inputData: {},
          loadingButtonContent: null,
          buttonDisable: false,
        },
      };
    case SEND_TIP_BY_STRIPE_FAILURE:
      return {
        ...state,
        tipStripe: {
          data: {},
          loading: true,
          error: action.error,
          inputData: {},
          loadingButtonContent: null,
          buttonDisable: false,
        },
      };
    case SEND_TIP_BY_PAYPAL_START:
      return {
        ...state,
        tipPaypal: {
          data: {},
          loading: true,
          error: false,
          inputData: action.data,
          loadingButtonContent: "Loading... Please wait.",
          buttonDisable: true,
        },
      };
    case SEND_TIP_BY_PAYPAL_SUCCESS:
      return {
        ...state,
        tipPaypal: {
          data: action.data,
          loading: false,
          error: false,
          inputData: {},
          loadingButtonContent: null,
          buttonDisable: false,
        },
      };
    case SEND_TIP_BY_PAYPAL_FAILURE:
      return {
        ...state,
        tipPaypal: {
          data: {},
          loading: true,
          error: action.error,
          inputData: {},
          loadingButtonContent: null,
          buttonDisable: false,
        },
      };
    case SEND_TIP_BY_WALLET_START:
      return {
        ...state,
        tipWallet: {
          data: {},
          loading: true,
          error: false,
          inputData: action.data,
          loadingButtonContent: "Loading... Please wait.",
          buttonDisable: true,
        },
      };
    case SEND_TIP_BY_WALLET_SUCCESS:
      return {
        ...state,
        tipWallet: {
          data: action.data,
          loading: false,
          error: false,
          inputData: {},
          loadingButtonContent: null,
          buttonDisable: false,
        },
      };
    case SEND_TIP_BY_WALLET_FAILURE:
      return {
        ...state,
        tipWallet: {
          data: {},
          loading: true,
          error: action.error,
          inputData: {},
          loadingButtonContent: null,
          buttonDisable: false,
        },
      };
    case SEND_TIP_BY_CCBILL_START:
      return {
        ...state,
        tipCCBill: {
          data: {},
          loading: true,
          error: false,
          inputData: action.data,
          loadingButtonContent: "Loading... Please wait.",
          buttonDisable: true,
        },
      };
    case SEND_TIP_BY_CCBILL_SUCCESS:
      return {
        ...state,
        tipCCBill: {
          data: action.data,
          loading: false,
          error: false,
          inputData: {},
          loadingButtonContent: null,
          buttonDisable: false,
        },
      };
    case SEND_TIP_BY_CCBILL_FAILURE:
      return {
        ...state,
        tipCCBill: {
          data: {},
          loading: true,
          error: action.error,
          inputData: {},
          loadingButtonContent: null,
          buttonDisable: false,
        },
      };
    case SEND_TIP_BY_COINPAYMENT_START:
      return {
        ...state,
        tipCoinPayment: {
          data: {},
          loading: true,
          error: false,
          inputData: action.data,
          loadingButtonContent: "Loading... Please wait.",
          buttonDisable: true,
        },
      };
    case SEND_TIP_BY_COINPAYMENT_SUCCESS:
      return {
        ...state,
        tipCoinPayment: {
          data: action.data,
          loading: false,
          error: false,
          inputData: {},
          loadingButtonContent: null,
          buttonDisable: false,
        },
      };
    case SEND_TIP_BY_COINPAYMENT_FAILURE:
      return {
        ...state,
        tipCoinPayment: {
          data: {},
          loading: true,
          error: action.error,
          inputData: {},
          loadingButtonContent: null,
          buttonDisable: false,
        },
      };
    case CUSTOM_TIP_PAYMENT_START:
      return {
        ...state,
        customTipPayment: {
          data: {},
          loading: true,
          error: false,
          loadingButtonContent: "Loading... Please wait.",
          buttonDisable: true,
        },
      };
    case CUSTOM_TIP_PAYMENT_SUCCESS:
      return {
        ...state,
        customTipPayment: {
          data: action.data,
          loading: false,
          error: false,
          loadingButtonContent: null,
          buttonDisable: false,
        },
      };
    case CUSTOM_TIP_PAYMENT_FAILURE:
      return {
        ...state,
        customTipPayment: {
          data: {},
          loading: true,
          error: action.error,
          loadingButtonContent: null,
          buttonDisable: false,
        },
      };
    case FETCH_SUBSCRIPTION_LIST_START:
      return {
        ...state,
        subscriptionList: {
          data: {},
          loading: true,
          error: false,
        },
      };
    case FETCH_SUBSCRIPTION_LIST_SUCCESS:
      return {
        ...state,
        subscriptionList: {
          data: action.data,
          loading: false,
          error: false,
        },
      };
    case FETCH_SUBSCRIPTION_LIST_FAILURE:
      return {
        ...state,
        subscriptionList: {
          data: {},
          loading: true,
          error: action.error,
        },
      };

      case FETCH_MORE_SUBSCRIPTION_LIST_START:
        return state;


    default:
      return state;
  }
};

export default SendTipReducer;
