import React from 'react'
import { Modal, Button, Form } from 'react-bootstrap';
import { translate, t } from "react-multi-lang";
import * as Yup from "yup";
import { useDispatch, useSelector } from "react-redux";
import { Formik, Form as FORM, ErrorMessage, Field } from "formik";
import { postFileUploadStart } from '../../../store/actions/PostAction';
import { ButtonLoader } from '../../helper/Loader';

const AddLinkModal = (props) => {

  const dispatch = useDispatch();

  const fileUpload = useSelector((state) => state.post.fileUpload);

  const validationScehma = Yup.object().shape({
    youtube_link: Yup.string().required(t("required")),
  });

  const handleSumbit = (values) => {
    dispatch(postFileUploadStart(values));

  };

  return (
    <Modal
      size="md"
      aria-labelledby="contained-modal-title-vcenter"
      centered
      className="pay-amount-modal"
      show={props.addLinkModal}
      onHide={props.closeLinkModal}
    >
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter">
          {t("youtube_embed_link")}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Formik
          initialValues={{
            file_type: "url",
            youtube_link: "",
          }}
          validationSchema={validationScehma}
          onSubmit={handleSumbit}
        >
          {({ errors, touched, setFieldValue, resetForm, setFieldError, }) => (
            <FORM className="create-folder-form">
              <Form.Group controlId="formBasicEmail" className="mb-3">
                <Form.Label>{t("embed_link")}</Form.Label>
                <Field
                  type="text"
                  aria-label="text"
                  name="youtube_link"
                  className="form-control"
                  placeholder={t("url")}
                />
                <ErrorMessage
                  component="div"
                  name="youtube_link"
                  className="errorMsg"
                />
              </Form.Group>
              <Button variant="primary"
                type="submit"
                className="default-btn mt-3"
                disabled={fileUpload.buttonDisable}
              >
                {fileUpload.loadingButtonContent !== null ?
                  <ButtonLoader />
                  : t("add")
                }
              </Button>

            </FORM>
          )}
        </Formik>
      </Modal.Body>
    </Modal>
  )
}

export default translate(AddLinkModal);