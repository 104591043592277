import React, { useEffect, useState, useRef } from "react";
import {
  Modal,
  Container,
  Row,
  Col,
  Button,
  Form,
  Image,
  Media,
} from "react-bootstrap";
import "../LiveStreaming/LiveStreaming.css";
import { Link, useHistory, useLocation } from "react-router-dom";
import { connect } from "react-redux";
import { translate, t } from "react-multi-lang";
import AgoraRTC from "agora-rtc-sdk-ng";
import useAgoraRTC from "./useAgoraRTC";
import { liveVideoEndStart } from "../../store/actions/LiveVideoAction";
import configuration from "react-global-configuration";
import AgoraMediaPlayer from "../helper/AgoraMediaPlayer";
import { event } from "jquery";
import ReactPlayer from "react-player";
import { useSelector } from "react-redux";

const rtcclient = AgoraRTC.createClient({ mode: "live", codec: "vp8" });
const LiveStreamingCard = (props) => {
  const [pipMode, setPipMode] = useState(false);
  const [data, setData] = useState(false);
  const videoList = useSelector((state) => state.liveVideo.videoList);
  const localUser = useSelector((state) => state.liveVideo.localUser);
  const togglePictureInPicture = async () => {
    setPipMode(!pipMode);
  };
  const {
    localAudioTrack,
    localVideoTrack,
    leaveRtcChannel,
    join,
    joinState,
    remoteUsers,
    isStreamEnded,
    muteAudio,
    muteVideo,
    mediaStatus,
    toggleFullScreen,
  } = useAgoraRTC(rtcclient);

  const handleJoin = () => {
    const username = props.profile.data.name;
    let joinResponse = join(
      configuration.get("configData.agora_app_id"),
      props.liveVideoDetails.virtual_id,
      props.liveVideoDetails.agora_token
        ? props.liveVideoDetails.agora_token
        : null,
      props.isOwner === true ? "host" : "audience",
      "live"
    );
  };

  const history = useHistory();

  useEffect(() => {
    if (props.join) {
      handleJoin();
    }
  }, []);

  useEffect(() => {
    if (isStreamEnded) {
      props.dispatch(
        liveVideoEndStart({
          live_video_id: props.liveVideoDetails.live_video_id,
          isOwner: props.isOwner,
        })
      );
    }
  }, [isStreamEnded]);

  const leaveCall = async () => {
    await leaveRtcChannel(props.isOwner);
  };
  return (
    <>
      <div className="agora-video-container-box-virtual-experience">
        <div
          className={
            mediaStatus.video.fullScreen
              ? "full-streaming-card"
              : "live-streaming-card"
          }
          id="video-container"
          style={{
            backgroundImage: `url(${props.liveVideoDetails.snapshot})`,
          }}
        >
          {Object.keys(localUser.data).length > 0 && (
            <>
              <AgoraMediaPlayer
                videoTrack={localUser.data.videoTrack}
                useClassname={
                  mediaStatus.video.fullScreen
                    ? "agora-container-full"
                    : "agora-container"
                }
                mirror={true}
                pipMode={pipMode}
                localtrackID={localUser.data?.videoTrack?._ID}
              ></AgoraMediaPlayer>
            </>
          )}

          <div
            className="live-streaming-bg-img-sec"
            id="agora_profile_placeholder"
            style={{ display: "none" }}
          >
            <Image
              className="live-streaming-bg-img"
              src={props.liveVideoDetails.snapshot}
            />
          </div>

          {Object.keys(localUser.data).length > 0 &&
            localUser.data.joinState && (
              <>
                <div className="live-streaming-modal-action-btn-sec">
                  <ul className="list-unstyled">
                    <Media as="li">
                      <Button
                        className="modal-action-btn"
                        onClick={() => togglePictureInPicture()}
                      >
                        <Image
                          className="modal-action-btn-icon"
                          src={
                            window.location.origin +
                            "/assets/images/video-call/pip-open.png"
                          }
                        />
                      </Button>
                    </Media>
                    {props.isOwner &&
                      Object.keys(localUser.data).length > 0 && (
                        <>
                          <Media as="li">
                            <Button
                              className="modal-action-btn"
                              onClick={() => muteAudio()}
                            >
                              {localUser.data.mediaStatus.audio?.muted ? (
                                <Image
                                  className="modal-action-btn-icon"
                                  src={
                                    window.location.origin +
                                    "/assets/images/live-streaming/audio-mute.svg"
                                  }
                                  //  id="mute-audio"
                                />
                              ) : (
                                <Image
                                  className="modal-action-btn-icon"
                                  src={
                                    window.location.origin +
                                    "/assets/images/live-streaming/audio-icon.svg"
                                  }
                                  // id="unmute-audio"
                                  //  style={{ display: "none" }}
                                />
                              )}
                            </Button>
                          </Media>
                          <Media as="li">
                            <Button
                              className="modal-action-btn"
                              onClick={() => muteVideo()}
                            >
                              {localUser.data.mediaStatus.video?.muted ? (
                                <Image
                                  className="modal-action-btn-icon"
                                  src={
                                    window.location.origin +
                                    "/assets/images/live-streaming/video-hide.svg"
                                  }
                                  //id="mute-video"
                                />
                              ) : (
                                <Image
                                  className="modal-action-btn-icon"
                                  src={
                                    window.location.origin +
                                    "/assets/images/live-streaming/video.svg"
                                  }
                                  // style={{ display: "none" }}
                                  // id="unmute-video"
                                />
                              )}
                            </Button>
                          </Media>
                        </>
                      )}
                    <Media as="li">
                      <Button
                        className="modal-action-btn"
                        onClick={() => leaveCall()}
                      >
                        <Image
                          className="modal-action-btn-icon"
                          src={
                            window.location.origin +
                            "/assets/images/video-call/end-call.svg"
                          }
                        />
                      </Button>
                    </Media>
                  </ul>
                </div>
              </>
            )}
          <div className="live-streaming-full-screen-sec">
            <Button
              className="close-btn"
              onClick={() => toggleFullScreen(props.maximize)}
            >
              <Image
                className="live-streaming-full-screen-icon"
                src={
                  window.location.origin +
                  "/assets/images/live-streaming/full-screen.svg"
                }
              />
            </Button>
          </div>
        </div>
      </div>
      {!props.isOwner &&
        Object.keys(videoList.data).length > 0 &&
        props.showRemoteUser && (
          <div className="agora-video-container-box-virtual-experience">
            <div
              className={
                mediaStatus.video.fullScreen
                  ? "full-streaming-card"
                  : "live-streaming-card"
              }
              id="video-container"
              style={{
                backgroundImage: `url(${props.liveVideoDetails.snapshot})`,
              }}
            >
              <>
                {videoList.data[0].map((user, index) => (
                  <AgoraMediaPlayer
                    key={index}
                    videoTrack={user.videoTrack}
                    audioTrack={user.audioTrack}
                    useClassname={
                      mediaStatus.video.fullScreen
                        ? "agora-container-full"
                        : "agora-container"
                    }
                    mirror={true}
                  ></AgoraMediaPlayer>
                ))}
              </>

              <div
                className="live-streaming-bg-img-sec"
                id="agora_profile_placeholder"
                style={{ display: "none" }}
              >
                <Image
                  className="live-streaming-bg-img"
                  src={props.liveVideoDetails.snapshot}
                />
              </div>
              <div className="live-streaming-full-screen-sec">
                <Button
                  className="close-btn"
                  onClick={() => toggleFullScreen(props.maximize)}
                >
                  <Image
                    className="live-streaming-full-screen-icon"
                    src={
                      window.location.origin +
                      "/assets/images/live-streaming/full-screen.svg"
                    }
                  />
                </Button>
              </div>
            </div>
          </div>
        )}
      {props.isOwner &&
        Object.keys(videoList.data).length > 0 &&
        props.showRemoteUser && (
          <div className="agora-video-container-box-virtual-experience">
            <div
              className={
                mediaStatus.video.fullScreen
                  ? "full-streaming-card"
                  : "live-streaming-card"
              }
              id="video-container"
              style={{
                backgroundImage: `url(${props.liveVideoDetails.snapshot})`,
              }}
            >
              <>
                {videoList.data[0].map((user, index) => (
                  <AgoraMediaPlayer
                    key={index}
                    videoTrack={user.videoTrack}
                    audioTrack={user.audioTrack}
                    useClassname={
                      mediaStatus.video.fullScreen
                        ? "agora-container-full"
                        : "agora-container"
                    }
                    mirror={true}
                  ></AgoraMediaPlayer>
                ))}
              </>

              <div
                className="live-streaming-bg-img-sec"
                id="agora_profile_placeholder"
                style={{ display: "none" }}
              >
                <Image
                  className="live-streaming-bg-img"
                  src={props.liveVideoDetails.snapshot}
                />
              </div>
              <div className="live-streaming-full-screen-sec">
                <Button
                  className="close-btn"
                  onClick={() => toggleFullScreen()}
                >
                  <Image
                    className="live-streaming-full-screen-icon"
                    src={
                      window.location.origin +
                      "/assets/images/live-streaming/full-screen.svg"
                    }
                  />
                </Button>
              </div>
            </div>
          </div>
        )}
    </>
  );
};

const mapStateToPros = (state) => ({
  liveVideo: state.liveVideo.singleLiveVideo,
  profile: state.users.profile,
});

function mapDispatchToProps(dispatch) {
  return { dispatch };
}

export default connect(
  mapStateToPros,
  mapDispatchToProps
)(translate(LiveStreamingCard));
